




















import { SfButton } from '@storefront-ui/vue';
const __sfc_main = {};
__sfc_main.props = {
  image: {
    type: String,
    required: false,
    default: ''
  },
  heigthImage: {
    type: String,
    required: false,
    default: '17px'
  },
  dataCy: {
    type: String,
    required: true,
    default: 'dark_button_without_data-cy'
  },
  colorButton: {
    type: String,
    required: false,
    default: 'transparent'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  return {};
};
__sfc_main.components = Object.assign({
  SfButton
}, __sfc_main.components);
export default __sfc_main;
