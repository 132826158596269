





























































































































































import { SfMegaMenu, SfProductCard, SfScrollable, SfMenuItem, SfLoader } from '@storefront-ui/vue';
import { computed, ref, watch } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useImage } from '~/composables';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import SvgImage from '~/components/General/SvgImage.vue';
import { handleDiscountPercentage } from '~/modules/catalog/product/helpers/handleDiscountPercentage';
import type { Product } from '~/modules/catalog/product/types';
const __sfc_main = {};
__sfc_main.props = {
  visible: {
    type: Boolean,
    default: false
  },
  loadingProduct: {
    type: Boolean,
    default: false
  },
  searchTerm: {
    type: String,
    default: ''
  },
  debouncedSearchTerm: {
    type: String,
    default: ''
  },
  searchResults: {
    type: (Array as PropType<Product[] | null>),
    default: () => []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  const {
    isAuthenticated
  } = useUser();
  const {
    getMagentoImage,
    imageSizes
  } = useImage();
  const {
    isInWishlist,
    addItem,
    removeItem
  } = useWishlist();
  const loading = ref(false);
  const searchResultsWithWishlistInfo = computed(() => props.searchResults?.filter(product => {
    if (product !== null) {
      return {
        ...product,
        isInWishlist: isInWishlist({
          product
        })
      };
    }
  }));
  const addItemToWishlist = async product => {
    await (isInWishlist({
      product
    }) ? removeItem({
      product
    }) : addItem({
      product
    }));
  };
  const setLoading = () => {
    if (props.searchTerm !== props.debouncedSearchTerm) loading.value = true;else loading.value = false;
  };
  watch(props, () => {
    setLoading();
  });
  return {
    productGetters,
    handleDiscountPercentage,
    isAuthenticated,
    getMagentoImage,
    imageSizes,
    loading,
    searchResultsWithWishlistInfo,
    addItemToWishlist
  };
};
__sfc_main.components = Object.assign({
  SfMegaMenu,
  SfLoader,
  SfMenuItem,
  SfScrollable,
  SfProductCard,
  SvgImage
}, __sfc_main.components);
export default __sfc_main;
