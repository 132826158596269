






import { SfLink } from '@storefront-ui/vue';
import { useRoute } from '@nuxtjs/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  dataCy: {
    type: String,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const storeLanguage = route.value.path.substring(0, 3);
  const props = __props;
  return {
    storeLanguage
  };
};
__sfc_main.components = Object.assign({
  SfLink
}, __sfc_main.components);
export default __sfc_main;
