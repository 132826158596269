


















import { SfSearchBar } from '@storefront-ui/vue';
import { ref, useRoute } from '@nuxtjs/composition-api';
import debounce from 'lodash.debounce';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { Products } from '~/modules/GraphQL/types';
const __sfc_main = {};
__sfc_main.props = {
  isSearchOpen: {
    type: Boolean,
    default: false
  },
  itemsPerPage: {
    type: Number,
    default: 12
  },
  minTermLen: {
    type: Number,
    default: 0
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  const term = ref('');
  const route = useRoute();
  const {
    getProductList
  } = useProduct();
  const showSearch = () => {
    if (!props.isSearchOpen) {
      emit('set-is-open', true);
      emit('SearchBar:toggle', true);
      document.body.classList.add('no-scroll');
    }
  };
  const hideSearch = () => {
    emit('set-is-open', false);
    emit('set-search-results', null);
    document.body.classList.remove('no-scroll');
  };
  const closeSearch = (event: MouseEvent) => {
    const searchResultsEl = document.querySelector('.search');
    const closeTriggerElement = (event.target as HTMLElement);
    if (!searchResultsEl?.contains(closeTriggerElement)) {
      hideSearch();
      term.value = '';
    }
  };
  const debouncedSearch = debounce(async () => {
    emit('set-loading-product', true);
    emit('set-debounced-search-term', term.value);
    if (term.value.length < props.minTermLen) return;
    const productList: Products = await getProductList({
      pageSize: props.itemsPerPage,
      search: term.value
    });
    emit('set-loading-product', false);
    emit('set-search-results', productList!.items);
  }, 1000);
  const handleSearch = () => {
    emit('set-search-term', term.value);
    debouncedSearch();
    showSearch();
  };
  return {
    term,
    hideSearch,
    closeSearch,
    handleSearch
  };
};
__sfc_main.components = Object.assign({
  SfSearchBar
}, __sfc_main.components);
export default __sfc_main;
