import { ProductInterface } from "~/../api-client/lib";
import productGetters from "../getters/productGetters";

export const handleDiscountPercentage = (product: Readonly<ProductInterface>): Readonly<String> =>{
    if(product.stock_status === "OUT_OF_STOCK"){
      return null
    }
    const specialPrice: Readonly <number> = productGetters.getPrice(product).special;
    const regularPrice: Readonly <number> = productGetters.getPrice(product).regular;
    return specialPrice ? `-${100-Math.round((specialPrice/regularPrice)*100)}%`: null
}
