import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _563ab3e2 = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "" */))
const _683f944a = () => interopDefault(import('../pages/Cart.vue' /* webpackChunkName: "pages/Cart" */))
const _6370da26 = () => interopDefault(import('../pages/Cgu.vue' /* webpackChunkName: "pages/Cgu" */))
const _637ef1a7 = () => interopDefault(import('../pages/Cgv.vue' /* webpackChunkName: "pages/Cgv" */))
const _153adf32 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _77d9167e = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _16886889 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _79f8208a = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _a6dc893c = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _531de6e5 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))

const _28f5c033 = () => interopDefault(import('../pages/ContactUs.vue' /* webpackChunkName: "" */))
const _300d4fef = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _88014b46 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _a6cd2e32 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _dda144ce = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _4e8712dd = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _fcdf7d1a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _6dd704c6 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _229de8e9 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _41244511 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _22b34136 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _5ead1216 = () => interopDefault(import('../pages/DeliveryInformations.vue' /* webpackChunkName: "pages/DeliveryInformations" */))
const _a2fc8fcc = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _10a3cbaa = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "" */))
const _10091a2c = () => interopDefault(import('../pages/FindOrder.vue' /* webpackChunkName: "pages/FindOrder" */))
const _3713a65e = () => interopDefault(import('../pages/Register.vue' /* webpackChunkName: "" */))
const _7230517a = () => interopDefault(import('../pages/Lookbook.vue' /* webpackChunkName: "" */))
const _c205bca0 = () => interopDefault(import('../pages/StoreLocator.vue' /* webpackChunkName: "" */))
const _0292646c = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _27f483af = () => interopDefault(import('../pages/PrivacyPolicy.vue' /* webpackChunkName: "" */))
const _f11f0c42 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _c1907360 = () => interopDefault(import('../pages/SizeGuide.vue' /* webpackChunkName: "" */))
const _a3e339de = () => interopDefault(import('../pages/SocialLogin.vue' /* webpackChunkName: "pages/SocialLogin" */))
const _5924e152 = () => interopDefault(import('../modules/wishlist/pages/Wishlist.vue' /* webpackChunkName: "" */))
const _53c1ae78 = () => interopDefault(import('../pages/Checkout/ExternalCheckoutThankYou.vue' /* webpackChunkName: "pages/Checkout/ExternalCheckoutThankYou" */))
const _42d58fb4 = () => interopDefault(import('../modules/catalog/pages/product.vue' /* webpackChunkName: "" */))
const _58be0752 = () => interopDefault(import('../modules/catalog/pages/category.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-propos",
    component: _563ab3e2,
    name: "about"
  }, {
    path: "/About",
    component: _563ab3e2,
    name: "About"
  }, {
    path: "/Cart",
    component: _683f944a,
    name: "Cart"
  }, {
    path: "/Cgu",
    component: _6370da26,
    name: "Cgu"
  }, {
    path: "/Cgv",
    component: _637ef1a7,
    name: "Cgv"
  }, {
    path: "/checkout",
    component: _153adf32,
    name: "checkout",
    children: [{
      path: "billing",
      component: _77d9167e,
      name: "billing"
    }, {
      path: "payment",
      component: _16886889,
      name: "payment"
    }, {
      path: "shipping",
      component: _79f8208a,
      name: "shipping"
    }, {
      path: "thank-you",
      component: _a6dc893c,
      name: "thank-you"
    }, {
      path: "user-account",
      component: _531de6e5,
      name: "user-account"
    }]
  }, {
    path: "/collections",
    redirect: (to) => '/c',
    name: "Collections"
  }, {
    path: "/conditions-generales-utilisateurs",
    component: _6370da26,
    name: "cgu"
  }, {
    path: "/conditions-generales-vente",
    component: _637ef1a7,
    name: "cgv"
  }, {
    path: "/contact",
    component: _28f5c033,
    name: "contact-us"
  }, {
    path: "/ContactUs",
    component: _28f5c033,
    name: "ContactUs"
  }, {
    path: "/customer",
    component: _300d4fef,
    meta: {"titleLabel":"My Account"},
    name: "customer",
    children: [{
      path: "addresses-details",
      component: _88014b46,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "my-newsletter",
      component: _a6cd2e32,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter"
    }, {
      path: "my-profile",
      component: _dda144ce,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile"
    }, {
      path: "my-reviews",
      component: _4e8712dd,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews"
    }, {
      path: "my-wishlist",
      component: _fcdf7d1a,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist"
    }, {
      path: "order-history",
      component: _6dd704c6,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "addresses-details/create",
      component: _229de8e9,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _41244511,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "order-history/:orderId",
      component: _22b34136,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }]
  }, {
    path: "/DeliveryInformations",
    component: _5ead1216,
    name: "DeliveryInformations"
  }, {
    path: "/en",
    component: _a2fc8fcc,
    name: "home___en"
  }, {
    path: "/faq",
    component: _10a3cbaa,
    name: "faq"
  }, {
    path: "/Faq",
    component: _10a3cbaa,
    name: "Faq"
  }, {
    path: "/FindOrder",
    component: _10091a2c,
    name: "FindOrder"
  }, {
    path: "/fr",
    component: _a2fc8fcc,
    name: "home___fr"
  }, {
    path: "/Home",
    component: _a2fc8fcc,
    name: "Home"
  }, {
    path: "/informations-de-livraison",
    component: _5ead1216,
    name: "delivery"
  }, {
    path: "/inscription",
    component: _3713a65e,
    name: "register"
  }, {
    path: "/lookbook",
    component: _7230517a,
    name: "lookbook"
  }, {
    path: "/Lookbook",
    component: _7230517a,
    name: "Lookbook"
  }, {
    path: "/magasins",
    component: _c205bca0,
    name: "store-locator"
  }, {
    path: "/Page",
    component: _0292646c,
    name: "Page"
  }, {
    path: "/panier",
    component: _683f944a,
    name: "cart"
  }, {
    path: "/privacypolicy",
    component: _27f483af,
    name: "privacy"
  }, {
    path: "/PrivacyPolicy",
    component: _27f483af,
    name: "PrivacyPolicy"
  }, {
    path: "/Register",
    component: _3713a65e,
    name: "Register"
  }, {
    path: "/reset-password",
    component: _f11f0c42,
    alias: "/customer/account/createPassword",
    name: "reset-password"
  }, {
    path: "/ResetPassword",
    component: _f11f0c42,
    name: "ResetPassword"
  }, {
    path: "/retrouver-ma-commande",
    component: _10091a2c,
    name: "find-order"
  }, {
    path: "/sizeguide",
    component: _c1907360,
    name: "size-guide"
  }, {
    path: "/SizeGuide",
    component: _c1907360,
    name: "SizeGuide"
  }, {
    path: "/SocialLogin",
    component: _a3e339de,
    name: "SocialLogin"
  }, {
    path: "/StoreLocator",
    component: _c205bca0,
    name: "StoreLocator"
  }, {
    path: "/wishlist",
    component: _5924e152,
    name: "wishlist"
  }, {
    path: "/Checkout/ExternalCheckoutThankYou",
    component: _53c1ae78,
    name: "Checkout-ExternalCheckoutThankYou"
  }, {
    path: "/en/a-propos",
    component: _563ab3e2,
    name: "about___en"
  }, {
    path: "/en/About",
    component: _563ab3e2,
    name: "About___en"
  }, {
    path: "/en/Cart",
    component: _683f944a,
    name: "Cart___en"
  }, {
    path: "/en/Cgu",
    component: _6370da26,
    name: "Cgu___en"
  }, {
    path: "/en/Cgv",
    component: _637ef1a7,
    name: "Cgv___en"
  }, {
    path: "/en/checkout",
    component: _153adf32,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _77d9167e,
      name: "billing___en"
    }, {
      path: "payment",
      component: _16886889,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _79f8208a,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _a6dc893c,
      name: "thank-you___en"
    }, {
      path: "user-account",
      component: _531de6e5,
      name: "user-account___en"
    }]
  }, {
    path: "/en/conditions-generales-utilisateurs",
    component: _6370da26,
    name: "cgu___en"
  }, {
    path: "/en/conditions-generales-vente",
    component: _637ef1a7,
    name: "cgv___en"
  }, {
    path: "/en/contact",
    component: _28f5c033,
    name: "contact-us___en"
  }, {
    path: "/en/ContactUs",
    component: _28f5c033,
    name: "ContactUs___en"
  }, {
    path: "/en/customer",
    component: _300d4fef,
    meta: {"titleLabel":"My Account"},
    name: "customer___en",
    children: [{
      path: "addresses-details",
      component: _88014b46,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en"
    }, {
      path: "my-newsletter",
      component: _a6cd2e32,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___en"
    }, {
      path: "my-profile",
      component: _dda144ce,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en"
    }, {
      path: "my-reviews",
      component: _4e8712dd,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___en"
    }, {
      path: "my-wishlist",
      component: _fcdf7d1a,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___en"
    }, {
      path: "order-history",
      component: _6dd704c6,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en"
    }, {
      path: "addresses-details/create",
      component: _229de8e9,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _41244511,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en"
    }, {
      path: "order-history/:orderId",
      component: _22b34136,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en"
    }]
  }, {
    path: "/en/DeliveryInformations",
    component: _5ead1216,
    name: "DeliveryInformations___en"
  }, {
    path: "/en/faq",
    component: _10a3cbaa,
    name: "faq___en"
  }, {
    path: "/en/Faq",
    component: _10a3cbaa,
    name: "Faq___en"
  }, {
    path: "/en/FindOrder",
    component: _10091a2c,
    name: "FindOrder___en"
  }, {
    path: "/en/Home",
    component: _a2fc8fcc,
    name: "Home___en"
  }, {
    path: "/en/informations-de-livraison",
    component: _5ead1216,
    name: "delivery___en"
  }, {
    path: "/en/inscription",
    component: _3713a65e,
    name: "register___en"
  }, {
    path: "/en/lookbook",
    component: _7230517a,
    name: "lookbook___en"
  }, {
    path: "/en/Lookbook",
    component: _7230517a,
    name: "Lookbook___en"
  }, {
    path: "/en/magasins",
    component: _c205bca0,
    name: "store-locator___en"
  }, {
    path: "/en/Page",
    component: _0292646c,
    name: "Page___en"
  }, {
    path: "/en/panier",
    component: _683f944a,
    name: "cart___en"
  }, {
    path: "/en/privacypolicy",
    component: _27f483af,
    name: "privacy___en"
  }, {
    path: "/en/PrivacyPolicy",
    component: _27f483af,
    name: "PrivacyPolicy___en"
  }, {
    path: "/en/Register",
    component: _3713a65e,
    name: "Register___en"
  }, {
    path: "/en/reset-password",
    component: _f11f0c42,
    alias: "/customer/account/createPassword",
    name: "reset-password___en"
  }, {
    path: "/en/ResetPassword",
    component: _f11f0c42,
    name: "ResetPassword___en"
  }, {
    path: "/en/retrouver-ma-commande",
    component: _10091a2c,
    name: "find-order___en"
  }, {
    path: "/en/sizeguide",
    component: _c1907360,
    name: "size-guide___en"
  }, {
    path: "/en/SizeGuide",
    component: _c1907360,
    name: "SizeGuide___en"
  }, {
    path: "/en/SocialLogin",
    component: _a3e339de,
    name: "SocialLogin___en"
  }, {
    path: "/en/StoreLocator",
    component: _c205bca0,
    name: "StoreLocator___en"
  }, {
    path: "/en/wishlist",
    component: _5924e152,
    name: "wishlist___en"
  }, {
    path: "/fr/a-propos",
    component: _563ab3e2,
    name: "about___fr"
  }, {
    path: "/fr/About",
    component: _563ab3e2,
    name: "About___fr"
  }, {
    path: "/fr/Cart",
    component: _683f944a,
    name: "Cart___fr"
  }, {
    path: "/fr/Cgu",
    component: _6370da26,
    name: "Cgu___fr"
  }, {
    path: "/fr/Cgv",
    component: _637ef1a7,
    name: "Cgv___fr"
  }, {
    path: "/fr/checkout",
    component: _153adf32,
    name: "checkout___fr",
    children: [{
      path: "billing",
      component: _77d9167e,
      name: "billing___fr"
    }, {
      path: "payment",
      component: _16886889,
      name: "payment___fr"
    }, {
      path: "shipping",
      component: _79f8208a,
      name: "shipping___fr"
    }, {
      path: "thank-you",
      component: _a6dc893c,
      name: "thank-you___fr"
    }, {
      path: "user-account",
      component: _531de6e5,
      name: "user-account___fr"
    }]
  }, {
    path: "/fr/conditions-generales-utilisateurs",
    component: _6370da26,
    name: "cgu___fr"
  }, {
    path: "/fr/conditions-generales-vente",
    component: _637ef1a7,
    name: "cgv___fr"
  }, {
    path: "/fr/contact",
    component: _28f5c033,
    name: "contact-us___fr"
  }, {
    path: "/fr/ContactUs",
    component: _28f5c033,
    name: "ContactUs___fr"
  }, {
    path: "/fr/customer",
    component: _300d4fef,
    meta: {"titleLabel":"My Account"},
    name: "customer___fr",
    children: [{
      path: "addresses-details",
      component: _88014b46,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___fr"
    }, {
      path: "my-newsletter",
      component: _a6cd2e32,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___fr"
    }, {
      path: "my-profile",
      component: _dda144ce,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___fr"
    }, {
      path: "my-reviews",
      component: _4e8712dd,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___fr"
    }, {
      path: "my-wishlist",
      component: _fcdf7d1a,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___fr"
    }, {
      path: "order-history",
      component: _6dd704c6,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___fr"
    }, {
      path: "addresses-details/create",
      component: _229de8e9,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___fr"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _41244511,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___fr"
    }, {
      path: "order-history/:orderId",
      component: _22b34136,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___fr"
    }]
  }, {
    path: "/fr/DeliveryInformations",
    component: _5ead1216,
    name: "DeliveryInformations___fr"
  }, {
    path: "/fr/faq",
    component: _10a3cbaa,
    name: "faq___fr"
  }, {
    path: "/fr/Faq",
    component: _10a3cbaa,
    name: "Faq___fr"
  }, {
    path: "/fr/FindOrder",
    component: _10091a2c,
    name: "FindOrder___fr"
  }, {
    path: "/fr/Home",
    component: _a2fc8fcc,
    name: "Home___fr"
  }, {
    path: "/fr/informations-de-livraison",
    component: _5ead1216,
    name: "delivery___fr"
  }, {
    path: "/fr/inscription",
    component: _3713a65e,
    name: "register___fr"
  }, {
    path: "/fr/lookbook",
    component: _7230517a,
    name: "lookbook___fr"
  }, {
    path: "/fr/Lookbook",
    component: _7230517a,
    name: "Lookbook___fr"
  }, {
    path: "/fr/magasins",
    component: _c205bca0,
    name: "store-locator___fr"
  }, {
    path: "/fr/Page",
    component: _0292646c,
    name: "Page___fr"
  }, {
    path: "/fr/panier",
    component: _683f944a,
    name: "cart___fr"
  }, {
    path: "/fr/privacypolicy",
    component: _27f483af,
    name: "privacy___fr"
  }, {
    path: "/fr/PrivacyPolicy",
    component: _27f483af,
    name: "PrivacyPolicy___fr"
  }, {
    path: "/fr/Register",
    component: _3713a65e,
    name: "Register___fr"
  }, {
    path: "/fr/reset-password",
    component: _f11f0c42,
    alias: "/customer/account/createPassword",
    name: "reset-password___fr"
  }, {
    path: "/fr/ResetPassword",
    component: _f11f0c42,
    name: "ResetPassword___fr"
  }, {
    path: "/fr/retrouver-ma-commande",
    component: _10091a2c,
    name: "find-order___fr"
  }, {
    path: "/fr/sizeguide",
    component: _c1907360,
    name: "size-guide___fr"
  }, {
    path: "/fr/SizeGuide",
    component: _c1907360,
    name: "SizeGuide___fr"
  }, {
    path: "/fr/SocialLogin",
    component: _a3e339de,
    name: "SocialLogin___fr"
  }, {
    path: "/fr/StoreLocator",
    component: _c205bca0,
    name: "StoreLocator___fr"
  }, {
    path: "/fr/wishlist",
    component: _5924e152,
    name: "wishlist___fr"
  }, {
    path: "/en/Checkout/ExternalCheckoutThankYou",
    component: _53c1ae78,
    name: "Checkout-ExternalCheckoutThankYou___en"
  }, {
    path: "/fr/Checkout/ExternalCheckoutThankYou",
    component: _53c1ae78,
    name: "Checkout-ExternalCheckoutThankYou___fr"
  }, {
    path: "/en/p/:id/:slug",
    component: _42d58fb4,
    name: "product___en"
  }, {
    path: "/fr/p/:id/:slug",
    component: _42d58fb4,
    name: "product___fr"
  }, {
    path: "/en/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _58be0752,
    name: "category___en"
  }, {
    path: "/fr/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _58be0752,
    name: "category___fr"
  }, {
    path: "/en/:slug+",
    component: _0292646c,
    name: "page___en"
  }, {
    path: "/fr/:slug+",
    component: _0292646c,
    name: "page___fr"
  }, {
    path: "/p/:id/:slug/",
    component: _42d58fb4,
    name: "product"
  }, {
    path: "/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _58be0752,
    name: "category"
  }, {
    path: "/",
    component: _a2fc8fcc,
    name: "home"
  }, {
    path: "/:slug+",
    component: _0292646c,
    name: "page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
