



























import { SfLink, SfList } from '@storefront-ui/vue';
import { useUiHelpers } from '~/composables';
const __sfc_main = {};
__sfc_main.props = {
  categoryName: String,
  categoryTitle: String,
  subCategories: Array,
  showChildren: Boolean
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    localePath,
    getCatLink
  } = useUiHelpers();
  const displayListSubCategories = ['Prestige', 'Collection', 'Mère', 'Mariage', 'Événements', 'Promotions', 'PROMLIFE', 'Marriage', 'Events', 'Mother'];
  const shouldDisplaySubCategory = subcategory => !displayListSubCategories.includes(subcategory.name);
  const getAllProductPath = (style: String) => {
    switch (style) {
      case 'Accessoires':
      case 'Accessories':
        return '/c/accessoires.html';
      case 'Mariage':
      case 'Marriage':
        return '/c/robes/mariage.html';
      case 'Événements':
      case 'Events':
        return '/c/robes/evenements.html';
      case 'Promotions':
      case 'Promotions':
        return '/c/robes/promotions.html';
      default:
        return '/c/robes.html';
    }
  };
  return {
    localePath,
    getCatLink,
    shouldDisplaySubCategory,
    getAllProductPath
  };
};
__sfc_main.components = Object.assign({
  SfLink,
  SfList
}, __sfc_main.components);
export default __sfc_main;
