import { render, staticRenderFns } from "./Shortcut.vue?vue&type=template&id=466d1876&scoped=true&"
import script from "./Shortcut.vue?vue&type=script&lang=ts&"
export * from "./Shortcut.vue?vue&type=script&lang=ts&"
import style0 from "./Shortcut.vue?vue&type=style&index=0&id=466d1876&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466d1876",
  null
  
)

export default component.exports