import {ref, onMounted, onUnmounted, nextTick} from '@nuxtjs/composition-api';

export function useIsMobile() {
  const isMobile = ref(false);

  const checkIfMobile = () => {
    isMobile.value = window.innerWidth <= 1023;
  };

  onMounted(() => {
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', checkIfMobile);
  });

  return {isMobile};
}


export function useIsTablet() {
  const isTablet = ref(false);
  const checkIfTablet = () => {
    isTablet.value = window.innerWidth <= 1023 && window.innerWidth > 500;
  };

  onMounted(() => {
    checkIfTablet();
    window.addEventListener('resize', checkIfTablet);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', checkIfTablet);
  });

  return {isTablet};
}
