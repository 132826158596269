


















import { SfLink } from '@storefront-ui/vue';
const __sfc_main = {};
__sfc_main.props = {
  label: {
    type: String,
    default: ''
  },
  link: {
    type: [String, Object],
    default: ''
  },
  hasChildren: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
__sfc_main.components = Object.assign({
  SfLink
}, __sfc_main.components);
export default __sfc_main;
