











































import { PropType, ref, onMounted } from '@nuxtjs/composition-api';
import { CategoryTree } from '~/modules/GraphQL/types';
import CategoriesPicture from './SubCategories/CategoriesPicture.vue';
import CategoriesList from './SubCategories/CategoriesList.vue';
const __sfc_main = {};
__sfc_main.props = {
  currentCategory: {
    type: (Object as PropType<CategoryTree | null>),
    default: () => null
  },
  hasFocus: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const displayList = ['Événements', 'Mariage', 'Promotions', 'Events', 'Marriage'];
  const props = __props;
  const hasChildren = (category: CategoryTree) => Boolean(category?.children.length > 0);
  const lvl1CatRefs = ref<string>([]);
  const lvl2CatRefs = ref<string>([]);
  const lvl2GroupedCatRefs = ref<string>([]);
  const shouldDisplayCategory = category => displayList.includes(category.name);
  const getGroupedLvl2CatRefs = () => {
    let current = 0;
    const result = [];
    lvl1CatRefs.value.forEach(lvl1CatRef => {
      const groupCount = Number(lvl1CatRef.$attrs['data-children']);
      result.push(lvl2CatRefs.value.slice(current, current + groupCount));
      current += groupCount;
    });
    return result;
  };
  let lvl1CatFocusIdx = 0;
  const setupNav = () => {
    lvl2CatFocusIdx = -1;
    lvl1CatRefs.value[lvl1CatFocusIdx].$el.focus();
    lvl2GroupedCatRefs.value = getGroupedLvl2CatRefs();
  };
  onMounted(() => {
    if (props.hasFocus) {
      setupNav();
    }
  });
  const dressesPhotos = [{
    picture: 'Dresses/Collection.png',
    alt: 'Collection',
    title: 'Collection',
    link: '/c/robes/collection.html'
  }, {
    picture: 'Dresses/Prestige.png',
    alt: 'Prestige',
    title: 'Prestige',
    link: '/c/robes/prestige.html'
  }, {
    picture: 'Dresses/Mere.png',
    alt: 'Mere',
    title: 'mother',
    link: '/c/robes/mere.html'
  }, {
    picture: 'Dresses/Promlife.png',
    alt: 'Promlife',
    title: 'Promlife',
    link: '/c/robes/promlife.html'
  }];
  const accessoriesPhotos = [{
    picture: 'Accessories/Fourrure.png',
    alt: 'Fourrure',
    title: 'fur',
    link: '/c/accessoires/fourrure.html'
  }, {
    picture: 'Accessories/Bolero.png',
    alt: 'Bolero',
    title: 'Bolero',
    link: '/c/accessoires/boleros.html'
  }, {
    picture: 'Accessories/Bijoux.png',
    alt: 'Bijoux',
    title: 'Jewelry',
    link: '/c/accessoires/bijoux.html'
  }];
  return {
    hasChildren,
    shouldDisplayCategory,
    dressesPhotos,
    accessoriesPhotos
  };
};
__sfc_main.components = Object.assign({
  CategoriesList,
  CategoriesPicture
}, __sfc_main.components);
export default __sfc_main;
