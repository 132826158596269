















import PictureItem from './PictureItem.vue';
const __sfc_main = {};
__sfc_main.props = {
  pictures: {
    type: Array,
    default: () => []
  },
  type: {
    type: String,
    default: 'Robes'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
__sfc_main.components = Object.assign({
  PictureItem
}, __sfc_main.components);
export default __sfc_main;
