













import { useContext, useRouter } from '@nuxtjs/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  link: {
    type: String,
    default: '',
    require: false
  },
  picture: {
    type: String,
    required: true
  },
  alt: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  dataCy: {
    type: String,
    required: true
  },
  category: {
    type: String,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const app = useContext();
  const props = __props;
  const handleClick = () => {
    router.push(app.localePath(props.link));
  };
  return {
    handleClick
  };
};
export default __sfc_main;
