export function useRedirectToNetwork(network: string) {
    switch (network) {
        case 'facebook':
             return 'https://fr-fr.facebook.com/PromuliasParis/';
        case 'instagram':
            return 'https://www.instagram.com/promulias/?hl=fr';
        case 'pinterest':
            return 'https://www.pinterest.fr/promulias/';
        case 'tiktok':
            return 'https://www.tiktok.com/@promulias';
        default:
        break;
    }
}