





































import { ref, onMounted, onBeforeUnmount } from '@nuxtjs/composition-api';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import { CategoryTree, ProductInterface, Products } from '~/modules/GraphQL/types';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import HeaderLogo from '~/components/Header/HeaderLogo.vue';
import StoreSwitcher from '~/components/Header/StoreSwitcher/StoreSwitcher.vue';
import HeaderShortcuts from '@/components/Header/Shortcuts/Shortcut.vue';
import SearchResults from '@/components/Header/SearchBar/SearchResults.vue';
import SearchBar from '@/components/Header/SearchBar/SearchBar.vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const {
    categories: categoryList,
    load: categoriesListLoad
  } = useCategory();
  const productSearchResults = ref<ProductInterface[] | null>(null);
  const loadingProduct = ref<ProductInterface[]>(null);
  const categoryTree = ref<CategoryTree[]>([]);
  const isSearchOpen = ref<boolean>(false);
  const searchTerm = ref<string>('null');
  const debouncedSearchTerm = ref('');
  const searchBarRef = ref(null);
  const searchResultsRef = ref(null);
  const searchIconRef = ref(document.getElementsByClassName('icon-Search'));
  const handleSearch = () => {
    isSearchOpen.value = !isSearchOpen.value;
    if (isSearchOpen.value) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };
  const closeSearch = () => {
    isSearchOpen.value = false;
    document.body.style.overflow = 'auto';
  };
  const handleClickOutside = (event: MouseEvent) => {
    const target = (event.target as HTMLElement);
    if (!searchBarRef.value?.contains(target) && !searchResultsRef.value?.contains(target) && !searchIconRef.value[0]?.contains(target)) {
      closeSearch();
    }
  };
  onMounted(async () => {
    await categoriesListLoad({
      pageSize: 20
    });
    if (categoryList.value?.[0]?.children) {
      categoryTree.value = categoryList.value[0].children.filter((category: {
        include_in_menu: any;
      }) => category.include_in_menu);
    }
    document.addEventListener('click', handleClickOutside);
  });
  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
  });
  return {
    emit,
    productSearchResults,
    loadingProduct,
    categoryTree,
    isSearchOpen,
    searchTerm,
    debouncedSearchTerm,
    searchBarRef,
    searchResultsRef,
    handleSearch,
    closeSearch
  };
};
__sfc_main.components = Object.assign({
  HeaderNavigation,
  HeaderLogo,
  StoreSwitcher,
  HeaderShortcuts,
  SearchBar,
  SearchResults
}, __sfc_main.components);
export default __sfc_main;
