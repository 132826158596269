













































































import { PropType, ref, useContext } from '@nuxtjs/composition-api';
import HeaderNavigationItem from './HeaderNavigationItem.vue';
import { CategoryTree } from '~/modules/GraphQL/types';
import HeaderNavigationSubcategories from './HeaderNavigationSubcategories.vue';
const __sfc_main = {};
__sfc_main.props = {
  categoryTree: {
    type: (Array as PropType<CategoryTree[]>),
    default: () => []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const category = ref(null);
  const {
    app
  } = useContext();
  const currentCategory = ref<CategoryTree>(null);
  const lvl0CatRefs = ref([]);
  const hasFocus = ref(false);
  let lvl0CatFocusIdx = 0;
  let focusedElement = null;
  const setCurrentCategory = (category: CategoryTree | null) => {
    currentCategory.value = category;
  };
  const hasChildren = (category: CategoryTree) => Boolean(category?.children);
  const setFocus = event => {
    focusedElement = event.target;
    lvl0CatFocusIdx = Number(event.target.dataset.index);
    hasFocus.value = true;
  };
  const focusRootElementAndHideSubcategories = () => {
    setCurrentCategory(null);
    if (focusedElement !== null) focusedElement.focus();
  };
  const navRight = () => {
    lvl0CatFocusIdx++;
    if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
      lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
      focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
    } else {
      lvl0CatFocusIdx--;
    }
  };
  const navLeft = () => {
    lvl0CatFocusIdx--;
    if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
      lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
      focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
    } else {
      lvl0CatFocusIdx++;
    }
  };
  const onMouseEnter = (category: CategoryTree) => {
    currentCategory.value = category;
    focusedElement = null;
    hasFocus.value = false;
    setTimeout(() => {
      currentCategory.value = category;
      focusedElement = null;
      hasFocus.value = false;
    }, 300);
  };
  return {
    category,
    app,
    currentCategory,
    lvl0CatRefs,
    hasFocus,
    setCurrentCategory,
    hasChildren,
    setFocus,
    focusRootElementAndHideSubcategories,
    navRight,
    navLeft,
    onMouseEnter
  };
};
__sfc_main.components = Object.assign({
  HeaderNavigationItem,
  HeaderNavigationSubcategories
}, __sfc_main.components);
export default __sfc_main;
